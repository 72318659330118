<template>
  <v-container>
    <div class="row" v-if="this.user.codigo_rol != 'CLIENTE'">
      <div class="col-12 col-md-4">

        <v-card
          class="mx-auto float-end w-100"
          tile
        >
          <v-list dense>
            <v-subheader>Lista de Videos</v-subheader>
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in tutorialesItems"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon >
                    mdi-play-circle
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.label"  @click="setVideoSrc(item.link, item.label)"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>


      </div>
      <div class="col-12 col-md-8">
        <h3>{{ videoLabel }}</h3>
        <v-card 
          id="#player-overlay" 
          :class="srcVideo === '' ? 'd-none' : 'd-block'"
          >
          <video
            ref="vid"
            :key="srcVideo" 
            controls
            preload="auto"
          >
            <source :src="srcVideo" type="video/mp4">
          </video>
        </v-card>
      </div>
    </div>
  </v-container>
</template>
<script>
//import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {authUsuarioComputed} from "@/state/helpers";

export default {
  page: {
      title: "Tutoriales",
      meta: [
          {
              name: "description",
              content: appConfig.description,
          },
      ],

  },
  components: {
      //Layout,
  },

  data() {
      return {
        tutorialesItems: [
          { label: 'Proyectos y lugares de instalación', link: 'proyectos_lugares_instalacion.mp4', icon: 'mdi-play-arrow' },
          { label: 'Consumo de Materiales', link: 'bodega_central_consumo_materiales.mp4', icon: 'mdi-play-arrow' },
          { label: 'Devolución de Materiales', link: 'bodega_central_devolucion_materiales.mp4', icon: 'mdi-play-arrow' },
          { label: 'Recepción de Materiales', link: 'bodega_central_recepcion_materiales.mp4', icon: 'mdi-play-arrow' },
          { label: 'Solicitud de Materiales', link: 'bodega_central_solicitud_materiales.mp4', icon: 'mdi-play-arrow' },
          { label: 'Vale de Consumo', link: 'bodega_movil_vale_consumo.mp4', icon: 'mdi-play-arrow' },
        ],
        right: null,
        srcVideo: '',
        videoLabel: '',
        selectedItem: '',
      };
  },
  created() {},
  mounted() {},
  methods: {
    setVideoSrc(src,label){
      console.log(src);
      this.srcVideo = 'https://s3.amazonaws.com/proyecto.fluxsolar.cl/public/videos/tutoriales/' + src;
      this.videoLabel = label;
    }
  },
  computed: {
    ...authUsuarioComputed
  },
};
</script>

<style scoped>
.tutorial-link{
  list-style: none;
}
#player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 999;
}

video {
  display: block;
  width: 100%;
  height: 100%;
}
</style>